* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: roc-grotesk, sans-serif; 
  font-weight: 300;
  font-style: normal;
  font-size: calc(10px + 2vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
