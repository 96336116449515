
:root {
  --darkNeutral: #000000;
  --neutral: #343430;
  --lightNeutral: #EFEBDA;

  --lightWarm: #EFA501;
  --warm: #EF5036;
  --darkWarm: #8F4757;

  --lightCool: #07D2B9;
  --cool: #008D98;
  --darkCool: #00516D;
}

/* Utilities and global styles ---------------------------------------- */

a {
  text-decoration: underline;
  color: var(--darkCool);
}

a:hover {
 color: #000;
}

br.brk_m {
  display: none;
}
br.brk_d {
  display: inline;
}





.clear {
  clear: both;
}

.fullHeight {
  display: block;
  position: relative;
  width: 100%;
  /* height: calc((var(--vh, 1vh) * 100) - 150px); */
  height: calc(100vh - 150px);
  top: 0;
  margin: 0;
  overflow: hidden;
}


/* Preloader ---------------------------------------- */

.preloader {
  /* size + position defined by fullHeight class */
}

.loaderLogo {
  display: block !important;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 62.5%;
  transform: translate(-50%, -50%);
}
.loaderBarContainer {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 70%;
  background-color: #ccc;
}

.loaderBar {
  width: 100%;
  height: 1px;
  position: relative;
  left: -100%;
  top: 0;
  background-color: var(--warm);
  -webkit-transition: left .7s; /* Safari prior 6.1 */
  transition: left .7s;
}

.loaderBar.p20 { left: -80%; }
.loaderBar.p40 { left: -60%; }
.loaderBar.p60 { left: -40%; }
.loaderBar.p80 { left: -20%; }
.loaderBar.p100 { left: 0; }

.intro {
  display: block;
  position: absolute;
  top:15%;
  width: 100%;
  text-align: center;
  opacity: 1;
  -webkit-transition: all 2s ease-out .7s; /* Safari prior 6.1 */
  transition: all 2s ease-out .7s;
}
.intro.loading {
  top: 25%;
}

.intro h1, .intro h2 {
  margin:0 auto;
  width: 80%;
  max-width: 800px;
  text-align: center;
}

/* .intro h2 {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 2.5rem;
} */

/* .intro h1 {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 1rem;
} */

.container {
  visibility: visible;
}

.container.loading {
  visibility: hidden;
}


/* Modal ---------------------------------------- */

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.6); */
  background: #000;
  z-index: 101;
}

.Modal .detail {
}

.Modal .detail img {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.Modal .detail iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close_x {
  position: fixed;
  display: block;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 100;
}


/* Nav ---------------------------------------- */

.Nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  width: 100%;
  padding: 32px;
  margin-bottom: 20px;
  background: #fff;
}

.loading .Nav {
  top: -50px;
  opacity: 0;
}

.Nav .logo {
  display: inline-block;
  max-width: 500px;
  width: 100%;
  padding: 0;
  margin-left: 6px;
}
.Nav .logo img {
  width: 100%;
  height: 100%;
}

.Nav .navLink{
  display: inline-block;
  color: #000;
  font-size: 1.2rem;
  margin: 6px 20px 0 10px;
  float: right;
}


/* TopGame ---------------------------------------- */

.TopGame {
  /* size + position defined by fullHeight class */
}

.TopGame canvas {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.TopGame .hand {
  position: absolute;
  left: 40%;
  top: 45%;
  margin-left: -100px;
  width: 400px;
  height: 120px;
}

.TopGame .clock {
  display: none;
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 20px;
  right: 20px;
  padding-top: 22px;
  text-align:center;
  border: 2px solid var(--warm);
  border-radius: 100px;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.TopGame .clock.hidden {
  right: -40px;
  opacity: 0;
  border-color: var(--warm);
  transform: scale(1);
}
.TopGame .clock.running {
  right: 40px;
  opacity: 1;
  border-color: var(--warm);
  transform: scale(1);
}
.TopGame .clock.stopped {
  right: 40px;
  opacity: 1;
  border-color: #77D812;
  transform: scale(1.3);
}
.TopGame .clock .seconds {
  display: inline-block;
  font-size: 2.5rem;
}
.TopGame .clock .milliseconds {
  display: inline-block;
  font-size: 1.5rem;
}

/* Grids ---------------------------------------- */

.wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
  top: 20px;
  max-width: 1920px;
  padding: 0 5rem;
}

.WorkGrid {
  margin-top: 20px;
}

.WorkGrid,
.PageGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
}

.Tile {
  position: relative;
  width: 100%;
  /* height: 100%; */
  grid-column: span 1;
  grid-row: span 1;
  background-color: #fff;
}

.Tile.tier3 {
  grid-column: span 4;
  grid-row: span 2;
}

.Tile.tier2 {
  grid-column: span 2;
  grid-row: span 2;
}

.Tile .loader {
  position: absolute;
  top: 0;
  left: 0;
}

.Tile video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Tile>img {
  width: 100%;
  height: 100%;
}
.Tile>.iframeContainer {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.Tile>.iframeContainer>iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* Flex Grids */

.flex-wrapper {
  width: 100%;
}
.flex-row {
  display: flex;
  gap: 6px;
  margin: 6px 0;
}
.flex-row>div,
.flex-row>a
{
  flex: 1;
}

/* Captions */

.Tile .captionContainer {
  opacity: 0;
}
.Tile .captionContainer:hover {
  opacity: 1;
}

.Tile .screener {
  width: 100%;
  height: 100%;
  position: absolute;
  left:0;
  top: 0;
  background-color:#000;
  opacity: 0.4;
}
.Tile .caption {
  position: absolute;
  left:0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-style: normal;
  font-size: 3vw;
  color: #fff;
}
.Tile .caption .eyes {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 400px;
}


.InfoGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  margin: 4rem 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: #000;
}

.InfoGrid h3 {
  font-weight: 700;
  font-size: 1rem;
}

.InfoGrid .description{
  grid-column: span 2;
  padding-right: 20px;
}

.InfoGrid .credits {
  grid-column: span 2;
}
.InfoGrid .credits ul, li {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.InfoGrid .credits em {
  color: #666;
  font-style: normal;
}


/* Footer ---------------------------------------- */

.Footer {
  position: relative;
  width: 100%;
  padding: 50px 0;
  margin-top: 100px;
  background-image: url('./assets/me.jpg');
  background-size:cover;
  background-position: top;
  background-attachment: fixed;
  font-size: 1.2rem;
  text-align: left;

  font-size: 2rem;
  font-weight: 600;
  line-height: 2.3rem;
}

@media only screen and (min-device-pixel-ratio: 2), 
only screen and (min-resolution: 192dpi) {
  .Footer {
    background-image: url('./assets/me@2x.jpg');
  }
}

.Footer .wrapper {
  display: flex;
  flex-direction: row;
}

.Footer .wrapper>div {
  flex: 1;
}

.Footer .me-mobile {
  display: none;
}

.Footer .info {
}

.Footer p {
  text-align: left;
}

.Footer .info .servicesHeader {
  display: block;
  margin: 40px 0 0 0;
  padding: 24px;
  text-align: center;
  border: 1px solid #000;
  border-width: 1px 1px 0 1px;
}

.Footer .info .services {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid #000;
  padding: 0;
}

.Footer .info .services ul {
  display: block;
  padding: 0;
  text-align: center;
}

.Footer .info .services ul li {
  margin: 20px 0;
}

.Footer .contact {
  display: flex;
  margin: 50px 0;
  padding: 0;
}

.Footer .contact .button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 2rem;
  border: 0;
  margin: 2px;
  padding: 32px 16px 32px;
  width: 100%;
  background-color: #000;
  border-radius: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.Footer .contact .button:hover {
  background-color: #0038FF;
}

.Footer .info .awards {
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0;
}

.Footer .info .awards .award {
  display: block;
  width: 100%;
  max-width: 126px;
  height: auto;
}


.Footer .closer {
  font-size: 1rem;
  text-align: center;
}

.Footer .closer a {
  color: #000;
}

.PageHeader {
  display: block;
  width: 100%;
  padding: 10px;
  background: #fff;
  text-align: center;
}

.PageHeader header {
  font-size: 2rem;
  font-weight: 700;
  margin: 6px 50px 10px 10px;
}



/* Shop ---------------------------------------- */

.Upsell {
  grid-column: span 1;
  display: block;
  width: 80%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}
.Upsell img {
  width: 100%;
  height: auto;
}
.Upsell p {
  font-size: .9rem;
  line-height: 1rem;
  text-decoration: none;
  color: #000;
  text-align: center;
}

.Shop {

}

.Shop #productList {
  min-height: 400px;
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin: 100px 0;

}

.Shop #productList iframe {
  grid-column: span 1;
  grid-row: span 1;
  margin-bottom: 30px;
}



@media only screen and (max-width: 800px) {

  .brk_m {
    display: inline;
  }

  .brk_d {
    display: none;
  }



  .intro {
    top:12%;
  }

  .intro.loading {
    top:25%;
  }





  .Nav .logo {
    /* width: 200px; */
  }

  .Nav .navLink{
    font-size: 1rem;
    margin: 6px 6px 0 10px;
  }



  .wrapper {
    padding: 0 1rem;
  }

  .WorkGrid,
  .PageGrid,
  .InfoGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .Tile.tier3 {
    grid-column: span 2;
    grid-row: span 2;
  }

  .Tile.tier2 {
    grid-column: span 2;
    grid-row: span 2;
  }

  .Tile .caption {
    font-size: 5.6vw;
  }


  



  .Footer {
    position: relative;
    width: 100%;
    background-image: none;
    margin-top: 0;
  }

  .Footer .wrapper {
    flex-direction: column;
  }
  

  .Footer .me-mobile {
    display: block;
    width: 100%;
  }


 

  .Footer .contact ul {
    display: block;
    margin: 50px 0;
    padding: 0;
    text-align: right;
  }

  .Footer .contact ul li {
    display: block;
    margin: 10px 0;
  }

  .Footer .closer a {
    color: #fff;
  }


  .Upsell {
    grid-column: span 1;
  }

  .Upsell img {
    float: none;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .Shop .wrapper {
    max-width: 550px;
  }

  .Shop #productList {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 1200px) {

  .Footer .info .services {
    flex-direction: column;
  }

  .Footer .info .services ul:nth-child(1) {
    margin-bottom: 0;
  }

  .Footer .info .services ul:nth-child(1) li:last-child {
    margin-bottom: 0;
  }

  .Footer .info .services ul:nth-child(2) {
    margin-top: 0;
  }
}